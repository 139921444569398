import React, { Component } from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { Container } from "../../global"
import { Chargebee, Buy} from "../chargebee";


const PriceField = (props) => {
  return (  
  <PriceWrapper>
        <Chargebee />
        {/*<Title>{props.title}</Title>*/}
        <Storage>{props.storage}GB</Storage>
        <p>storage</p>
        <Price>${props.cost}</Price>
        { props.type === 'monthly' && <div>monthly</div> }
        { props.type === 'annual' && <div>yearly</div> }
        <Buttons>
          <StyledBuy plan={props.id} text="Buy" />
        </Buttons>
  </PriceWrapper>
)}

const signupclick = (id) =>{
    console.log("Hi: " + id);
}

const Buttons = styled(Container)`
  justify-self: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const StyledBuy = styled(Buy)`
  font-weight: 500;
  font-size: 14px;
  align: center;
  color: white;
  letter-spacing: 1px;
  height: 60px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: ${props => props.theme.color.secondary};
  border-radius: 4px;
  padding: 0px 40px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 0;
  }
  align-self: flex-end;
  :disabled {
    background-color : gray;
  }
`

const Title = styled.h4`
    text-align: center;
    justify-self: flex-start
    flex-grow: 1;
`

const PriceWrapper = styled(Container)`
    width: 220px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
    box-sizing: border-box;
    background-color: lightgrey;
    padding: 12px;
    border: 2px solid darkgrey;
    margin: 12px;
    -webkit-transition: 0.3s;
    transition: 0.3s;

    :hover {
        box-shadow: 0 8px 12px 0 rgba(0,0,0,0.2)
    }
}
`

const Storage = styled.h1`
    text-align: center;
    justify-self: flex-end;
    margin-bottom: 5px;
    + p {
      margin: 0px;
      padding: 0px;
      text-align: center;
      margin-bottom: 30px;
    }
`

const Price = styled.h2`
    text-align: center;
    justify-self: flex-end;
    margin-bottom: 5px;
    + div{
      text-align: center;
      margin-bottom: 30px;
    }
`

export default PriceField
