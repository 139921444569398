import React from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"
import PriceField from "../components/common/plans/widget"

import Footer from "../components/sections/footer"

import styled from "styled-components"
import { Container } from "../components/global"
/*
 Test Page.  Probably not going to be used at launch
*/
const PricePage = () => (
  <Layout>
    <SEO title="Pricing" />
    <Navigation />
    <PriceSection>
        <PriceField storage="5" cost="$6" />
        <PriceField storage="20" cost="$12" />
    </PriceSection>
    <Footer />
  </Layout>
)

export default PricePage

const PriceSection = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 80px 0 40px;
`